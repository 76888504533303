// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

$border-width: 1px;
$theme-colors: (
        'theme1': #12639B,
        'theme2': #46230F,
        'gray-200': #f4f4f4,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$btn-font-weight: bold;
$headings-font-weight: 900;
$enable-rounded: false;
$enable-shadows: false;
$link-decoration: underline;
$border-radius: 0px;

$headings-color: $primary;

@import '@/src/scss/main.scss';

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss';  // Mijn omgeving css (sidebar theme)
@import '@/src/scss/components/dropdown-sub-menu.scss';
@import '@/src/scss/components/hero-video.scss';


$text-shadow: 2px 2px 5px rgba(0,0,0,0.4);

#main-content {
  @extend .mb-5;
}

.shoppingcart--icon {
  @extend .mr-2;
  @extend .my-auto;
}

.navbar-brand--img {
  width: 100px;
}

.top-bar {
  background-image: url('../svg/default-topbar-bg.svg');
}

.nav-link {
  color: $dark !important;
  &:hover {
    color: $primary !important;
  }
}

.navbar-nav {
  .fa-chevron-down {
    color: $primary;
  }
}

.social-icons {
  i {
    color: $white;
  }
}

.top-bar {
  ul {
    list-style: none;
    li {
      margin-left: 8px;
      a {
        color: $white;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}

.shopping-cart-link {
  a {
    color: $dark;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}

.footer--top {
  margin-bottom: -60px;
}

.footer--bottom {
  padding-top: 100px;
}

.footer-data {
  a {
    color: $white;
  }
}

.watermark--bar {
  border: $border-radius $border-radius 0 0;
}

.footer-cta--image {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
    height: auto !important;
  }
}

.actuality-banner-card {
  right: 0;
  bottom: 0;
  color: $dark;
  @extend .col-lg-5, .col-xl-4;
  @include media-breakpoint-up(lg) {
    position: absolute;
  }
}

.quick-menu-item {
  &:hover {
    transform: scale(1.2);
  }
  transition: transform .3s;
  .quicknav-title {
    color: $white;
    @extend h5, .mt-2;
  }
  img {
    width: 50px;
  }
}

.video-hero-wrapper {
  .video-hero-overlay-text {
    text-shadow: $text-shadow;
  }
  .video-hero--subtitle {
    display: none;
  }
}

.boschbad {
  .quick-menu-section {
    background-image: url('../svg/quicknav-flavor-boschbad.svg');
  }
  #rooster-plain {
    table {
      &::after {
        @include media-breakpoint-down(xl) {
          display: none;
        }
        background-image: url('../img/flamingo.png');
        content: "";
        width: 150px;
        height: 300px;
        display: block;
        position: absolute;
        left: calc(100% + 10px);
        top: 50%;
      }
    }
  }
  .highlight-banners-wrapper{
    .highlight-section {
      background-image: url('../img/sandy-beach.jpg');
    }
  }
}

.highlight-section {
  .highlight-flavor-01 {
    bottom: 3rem;
    left: 3rem;
  }
  .highlight-flavor-02 {
    top: 3rem;
    right: 3rem;
    @include media-breakpoint-up(xl) {
      top: 6rem;
      right: 6rem;
    }
  }
}

.malkander {
  .quick-menu-section {
    background-color: $primary;
  }
  #rooster-plain {
    table {
      &::after {
        @include media-breakpoint-down(xl) {
          display: none;
        }
        background-image: url('../svg/arrow-swirl.svg');
        content: "";
        width: 145px;
        height: 83px;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: calc(100% + 40px);
        top: 50%;
      }
    }
  }
}

.css-container {
  z-index: 2;
  @extend .container;
}



.highlight-banners-wrapper {
  .css-container {
    margin-bottom: 100px;
  }
  .highlight-section {
    background: $primary;
    position: absolute;
    width: 100%;
    height: 430px;
    bottom: -160px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 0;
    object-fit: cover;
    margin-bottom: 100px;
  }

  .highlight-card-top-img {
    width: 100%;
    height: 280px !important;
    position: relative;
    transition: height .8s;
    object-fit: cover;
  }

  .highlight-card-body {
    @extend .p-3;
    height: 170px;
    transition: all .8s;
    bottom: 0;
    position: absolute;
  }

  .highlight-read-on {
    display: none;
  }

  .highlight-card-hover-grad {
    background: linear-gradient(331deg, #1C7EC1, #0E3F61);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    bottom: 0;
  }

  .highlight-card {
    border-radius: $border-radius;
    background-color: $white;
    display: inline-block;
    outline: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    highlight-card-body {
      transition: all .4s ease-in;
      position: absolute;
      h5 {
        transition: color .3s;
      }
    }
    &:hover {
      .highlight-card-top-img {
        height: 100% !important;
      }
      .highlight-card-body {
        transform: translateY(-20px);
        z-index: 2;
        h5 {
          color: white;
        }
        p {
          display: none;
        }
      }
      .highlight-card-hover-grad {
        opacity: .8;
      }
      .highlight-read-on {
        display: block;
      }
    }
  }
}

figure.img-cover {
  width: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(lg) {
    height: 200px;
  }
  @include media-breakpoint-down(md) {
    height: 130px;
  }
}

.accordion {
  .card-header {
    @extend .bg-primary;
    button {
      color: $white;
    }
  }
}

.stretched-link::after {
  z-index: 999;
}
