$primary: #1C7EC1 !default;
$theme-contrast-color: #fff !default;
$theme1: #12639B;
$theme2: #46230F;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;


@import '@/src/scss/preset/zwembadenapeldoorn.scss';
@import "~daterangepicker";

.card {
  @extend .bg-gray-200, .border-0;
}

.wrapper {
  margin-bottom: -75px;
}
